import {
	createStore
} from 'vuex'

import {
	http
} from '@/@core/utils/axios'


import jwtDefaultConfig from '@/@core/utils/jwtConfig'
let jwtConfig = {
	...jwtDefaultConfig
}
// import appConfig from './modules/getters.js'

const store = createStore({
	state() {
		return {
			windowsStyle: {
				// width: window.innerWidth + 'px' || document.documentElement.clientWidth + 'px' || document.body.clientWidth + 'px',
				width: "100%",
				height: window.innerHeight + 'px' || document.documentElement.clientHeight + 'px' || document
					.body.clientHeight + 'px',
				'min-width': "1300px", //1366px
				// 'min-width': "900px",
				// 'min-height': "700px", //1366px
				'scrollbar-width': 'none',
				/* firefox */
				'-ms-overflow-style': 'none',
				/* IE 10+ */
				// 'overflow-x': 'auto',
				// 'overflow-y': 'hidden',

			},
			adminStyle:{
				'heardHeight':'60px',
				'breadcrumbHeight':'35px',
				'contentPadding':'10px 10px 10px 10px',
				'navtabHeight':'calc(100vh - 60px)',
				'navtabWidthSaas':'140px',
				'navtabWidthApplet':'105px',
				// 头部 面包屑 内容padding
				'contentbreadcrumbHeight':'calc(100vh - 60px - 35px - 20px)',
				'contentWidth':'calc(100vw - 140px)',
				'contentHeight':'calc(100vh - 60px)',
			},
			userInfosass: localStorage.getItem("userInfosass") || '',
			appletNavTabFlage: true,
			appletId: localStorage.getItem("appletId") || '',
			appletInfo: localStorage.getItem("appletInfo") || '',
			token: `${jwtConfig.tokenType} ` +localStorage.getItem("accessToken") || '',
		}
	},

	// mutations state成员操作（调用：this.$store.commit('state',参数)）
	mutations: {
		increment(state) {
			state.count++
		},
		getUserInfo(state, payload) {
			// console.log(payload)
			localStorage.setItem('userInfosass', payload)
			state.userInfosass = payload // 对应的去修改state数据就行了
		},
		getAppletNavFlage(state, payload) {
			state.appletNavTabFlage = payload // 对应的去修改state数据就行了
		},
		getAppletId(state, payload) {
			// console.log(payload)
			state.appletId = payload // 对应的去修改state数据就行了
			localStorage.setItem('appletId', payload)
		},
		getAppletInfo(state, payload) {
			// console.log(payload)
			state.appletInfo = payload // 对应的去修改state数据就行了
			localStorage.setItem('appletInfo', payload)
		},

	},

	// getters 加工计算state成员给外界 (调用:this.$store.getters.xxx)

	getters: {
		get_windowsStyle(state) {
			return state.windowsStyle;
		},
		get_adminStyle(state) {
			return state.adminStyle;
		},
		getUserInfo(state) {
			return JSON.parse(state.userInfosass);
		},
		getAppletNavFlage(state) {
			return state.appletNavTabFlage
		},
		getAppletId(state) {
			return state.appletId;
		},
		getAppletInfo(state) {
			return JSON.parse(state.appletInfo);
		},
		getToken(state) {
			return state.token;
		},
	},
	// actions 异步操作(调用:this.$store.dispatch(方法,参数))
	actions: {
		getHomeDataAction({
			commit
		}) {
			http.get("/api/user/getInfo").then(res => {
				commit("getUserInfo", JSON.stringify(res.data))
			})
		},
		getAppletInfo({
			commit
		}, data) {
			http.post("/api/info", data).then(res => {
				if (res.code == 200) {
					commit("getAppletInfo", JSON.stringify(res.data))
				} else {
					this.$message.error(res.message);
				}
			})
		},

	},
	// modules 模块化状态管理
	modules: {
		// appConfig,
	}
})

export default store
