export default {
  // Endpoints
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',


  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  firmName: '',//公司名称
  shortenedForm: '数度科技',//公司简称

  saasBaseURL: "https://sa.home.135k.com/",

  dirverAdminUrl: 'https://dj.home.135k.com/#/',
  saasAdminUrl: 'https://sa.home.135k.com/index.html/#/',
}
