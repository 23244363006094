export default [
    //开发
    {
        path: '/exploitApplet',
        name: 'exploitApplet',
        component: () => import('@/views/appletAdmin/exploitApplet/index.vue'),
        children: [
            {
                //模版管理
                path: '/appletDetail/appletTemplate',
                name: 'appletTemplate',
                component: () => import( /* webpackChunkName: "appletTemplate" */
                    '@/views/appletAdmin/exploitApplet/appletTemplate/appletTemplate.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '模版管理',
                    pageTitleTab: 'appletTemplate',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '模版管理',
                        active: false,
                    },],
                },

            },

            {
                //模版管理 
                path: '/appletDetail/appletTemplate/appletIndex',
                name: 'appletIndex',
                component: () => import( /* webpackChunkName: "appletIndex" */
                    '@/views/appletAdmin/exploitApplet/appletTemplate/appletIndex.vue'),
                children: [
                    {
                        //模版管理  主题色
                        path: '/appletDetail/appletTemplate/subjectColor',
                        name: 'subjectColor',
                        component: () => import( /* webpackChunkName: "subjectColor" */
                            '@/views/appletAdmin/exploitApplet/appletTemplate/subjectColor.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '模版管理',
                            pageTitleTab: 'appletTemplate',
                            pageTitleflage: true,
                            childrenTitle: '主题色',
                            childrenTitleTab: 'subjectColor',
                            breadcrumb: [{
                                text: '模版管理',
                                active: true,
                                to: {
                                    name: 'appletTemplate',
                                },
                            },
                            {
                                text: '主题色',
                                active: false,
                            },
                            ],
                        },
                    },
                    {
                        //模版管理  自定义页面
                        path: '/appletDetail/appletTemplate/customPage',
                        name: 'customPage',
                        component: () => import( /* webpackChunkName: "customPage" */
                            '@/views/appletAdmin/exploitApplet/appletTemplate/customPage.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '模版管理',
                            pageTitleTab: 'appletTemplate',
                            pageTitleflage: true,
                            childrenTitle: '自定义页面',
                            childrenTitleTab: 'customPage',
                            breadcrumb: [{
                                text: '模版管理',
                                active: true,
                                to: {
                                    name: 'appletTemplate',
                                },
                            },
                            {
                                text: '自定义页面',
                                active: false,
                            },
                            ],
                        },
                    },
                    {
                        //模版管理  页面管理
                        path: '/appletDetail/appletTemplate/pagesManage',
                        name: 'pagesManage',
                        component: () => import( /* webpackChunkName: "pagesManage" */
                            '@/views/appletAdmin/exploitApplet/appletTemplate/pagesManage.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '模版管理',
                            pageTitleTab: 'appletTemplate',
                            pageTitleflage: true,
                            childrenTitle: '页面管理',
                            childrenTitleTab: 'pagesManage',
                            breadcrumb: [{
                                text: '模版管理',
                                active: true,
                                to: {
                                    name: 'appletTemplate',
                                },
                            },
                            {
                                text: '页面管理',
                                active: false,
                            },
                            ],
                        },
                    },
                    {
                        //模版管理  页面路径
                        path: '/appletDetail/appletTemplate/pagesPath',
                        name: 'pagesPath',
                        component: () => import( /* webpackChunkName: "pagesPath" */
                            '@/views/appletAdmin/exploitApplet/appletTemplate/pagesPath.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '模版管理',
                            pageTitleTab: 'appletTemplate',
                            pageTitleflage: true,
                            childrenTitle: '页面路径',
                            childrenTitleTab: 'pagesPath',
                            breadcrumb: [{
                                text: '模版管理',
                                active: true,
                                to: {
                                    name: 'appletTemplate',
                                },
                            },
                            {
                                text: '页面路径',
                                active: false,
                            },
                            ],
                        },
                    },
                ]
            },





            {
                //版本管理
                path: '/appletDetail/appletBanben',
                name: 'appletBanben',
                component: () => import( /* webpackChunkName: "appletBanben" */
                    '@/views/appletAdmin/exploitApplet/appletVersions/appletBanben.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '版本管理',
                    pageTitleTab: 'appletBanben',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '版本管理',
                        active: false,
                    },],
                },

            },




            {
                //短信配置
                path: '/appletDetail/appletMessage/appletNoteIndex',
                name: 'appletNoteIndex',
                component: () => import( /* webpackChunkName: "appletNoteIndex" */
                    '@/views/appletAdmin/exploitApplet/appletMessage/appletNoteIndex.vue'),
                children: [
                    //短信配置  概括
                    {
                        path: '/appletDetail/appletMessage/NoteGeneralize',
                        name: 'NoteGeneralize',
                        component: () => import( /* webpackChunkName: "NoteGeneralize" */
                            '@/views/appletAdmin/exploitApplet/appletMessage/NoteGeneralize.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '短信配置',
                            pageTitleTab: 'appletMessage',
                            pageTitleflage: true,
                            childrenTitle: '概括',
                            childrenTitleTab: 'NoteGeneralize',
                            breadcrumb: [{
                                text: '短信配置',
                                active: false,
                            },],
                        },
                    },
                    {
                        //短信配置  短信签名
                        path: '/appletDetail/appletMessage/NoteSignature',
                        name: 'NoteSignature',
                        component: () => import( /* webpackChunkName: "NoteSignature" */
                            '@/views/appletAdmin/exploitApplet/appletMessage/NoteSignature.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '短信配置',
                            pageTitleTab: 'appletMessage',
                            pageTitleflage: true,
                            childrenTitle: '短信签名',
                            childrenTitleTab: 'NoteSignature',
                            breadcrumb: [{
                                text: '短信配置',
                                active: false,
                            },],
                        },
                    },
                    {
                        //短信配置  模版设置
                        path: '/appletDetail/appletMessage/NoteTemplateSet',
                        name: 'NoteTemplateSet',
                        component: () => import( /* webpackChunkName: "NoteTemplateSet" */
                            '@/views/appletAdmin/exploitApplet/appletMessage/NoteTemplateSet.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '短信配置',
                            pageTitleTab: 'appletMessage',
                            pageTitleflage: true,
                            childrenTitle: '模版设置',
                            childrenTitleTab: 'NoteTemplateSet',
                            breadcrumb: [{
                                text: '短信配置',
                                active: false,
                            },],
                        },
                    },
                    {
                        //短信配置  群发助手
                        path: '/appletDetail/appletMessage/NoteSend',
                        name: 'NoteSend',
                        component: () => import( /* webpackChunkName: "NoteSend" */
                            '@/views/appletAdmin/exploitApplet/appletMessage/NoteSend.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '短信配置',
                            pageTitleTab: 'appletMessage',
                            pageTitleflage: true,
                            childrenTitle: '群发助手',
                            childrenTitleTab: 'NoteSend',
                            breadcrumb: [{
                                text: '短信配置',
                                active: false,
                            },],
                        },
                    },
                    {
                        //短信配置  信息统计
                        path: '/appletDetail/appletMessage/NoteStatistics',
                        name: 'NoteStatistics',
                        component: () => import( /* webpackChunkName: "NoteStatistics" */
                            '@/views/appletAdmin/exploitApplet/appletMessage/NoteStatistics.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '短信配置',
                            pageTitleTab: 'appletMessage',
                            pageTitleflage: true,
                            childrenTitle: '信息统计',
                            childrenTitleTab: 'NoteStatistics',
                            breadcrumb: [{
                                text: '短信配置',
                                active: false,
                            },],
                        },
                    },
                    {
                        //短信配置  充值记录
                        path: '/appletDetail/appletMessage/NoteRechargeRecord',
                        name: 'NoteRechargeRecord',
                        component: () => import( /* webpackChunkName: "NoteRechargeRecord" */
                            '@/views/appletAdmin/exploitApplet/appletMessage/NoteRechargeRecord.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '短信配置',
                            pageTitleTab: 'appletMessage',
                            pageTitleflage: true,
                            childrenTitle: '充值记录',
                            childrenTitleTab: 'NoteRechargeRecord',
                            breadcrumb: [{
                                text: '短信配置',
                                active: false,
                            },],
                        },
                    }

                ],
            },



            {
                //支付配置
                path: '/appletDetail/appletPay/appletPayIndex',
                name: 'appletPayIndex',
                component: () => import( /* webpackChunkName: "appletPayIndex" */
                    '@/views/appletAdmin/exploitApplet/appletPay/appletPayIndex.vue'),
                children: [
                    //支付配置  支付配置
                    {
                        path: '/appletDetail/appletPay/PayConfiguration',
                        name: 'PayConfiguration',
                        component: () => import( /* webpackChunkName: "PayConfiguration" */
                            '@/views/appletAdmin/exploitApplet/appletPay/PayConfiguration.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '支付配置',
                            pageTitleTab: 'appletPay',
                            pageTitleflage: true,
                            childrenTitle: '支付配置',
                            childrenTitleTab: 'PayConfiguration',
                            breadcrumb: [{
                                text: '支付配置',
                                active: false,
                            },],
                        },
                    },
                    {
                        //支付配置  模版管理
                        path: '/appletDetail/appletPay/PayTemplateSet',
                        name: 'PayTemplateSet',
                        component: () => import( /* webpackChunkName: "PayTemplateSet" */
                            '@/views/appletAdmin/exploitApplet/appletPay/PayTemplateSet.vue'),
                        meta: {
                            tabTitle: "开发",
                            tabTitleflage: true,
                            pageTitle: '支付配置',
                            pageTitleTab: 'appletPay',
                            pageTitleflage: true,
                            childrenTitle: '模版管理',
                            childrenTitleTab: 'PayTemplateSet',
                            breadcrumb: [{
                                text: '支付配置',
                                active: false,
                            },],
                        },
                    },
                ],
            },





            {
                //服务器域名
                path: '/appletDetail/appletServer',
                name: 'appletServer',
                component: () => import( /* webpackChunkName: "appletServer" */
                    '@/views/appletAdmin/exploitApplet/appletServer/appletServer.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '服务器域名',
                    pageTitleTab: 'appletServer',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '服务器域名',
                        active: false,
                    },],
                },
            },
            


            {
                //业务域名
                path: '/appletDetail/appletBusiness',
                name: 'appletBusiness',
                component: () => import( /* webpackChunkName: "appletBusiness" */
                    '@/views/appletAdmin/exploitApplet/appletBusiness/appletBusiness.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '业务域名',
                    pageTitleTab: 'appletBusiness',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '业务域名',
                        active: false,
                    },],
                },

            },


            {
                //接口设置
                path: '/appletDetail/appletConnectorSet',
                name: 'appletConnectorSet',
                component: () => import( /* webpackChunkName: "appletConnectorSet" */
                    '@/views/appletAdmin/exploitApplet/appletConnectorSet/appletConnectorSet.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '接口设置',
                    pageTitleTab: 'appletConnectorSet',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '接口设置',
                        active: false,
                    },],
                },

            },



            {
                //模板消息
                path: '/appletDetail/appletTemplateInfo',
                name: 'appletTemplateInfo',
                component: () => import( /* webpackChunkName: "appletTemplateInfo" */
                    '@/views/appletAdmin/exploitApplet/appletTemplateInfo/TemplateInfo.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '模板消息',
                    pageTitleTab: 'appletTemplateInfo',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '模板消息',
                        active: false,
                    },],
                },

            },



            {
                //关联设置
                path: '/appletDetail/appletRelevanceSet',
                name: 'appletRelevanceSet',
                component: () => import( /* webpackChunkName: "appletRelevanceSet" */
                    '@/views/appletAdmin/exploitApplet/appletRelevanceSet/appletRelevanceSet.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '关联设置',
                    pageTitleTab: 'appletRelevanceSet',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '关联设置',
                        active: false,
                    },],
                },

            },



            {
                //地图配置
                path: '/appletDetail/appletMapSet',
                name: 'appletMapSet',
                component: () => import( /* webpackChunkName: "appletMapSet" */
                    '@/views/appletAdmin/exploitApplet/appletMapSet/appletMapSet.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '地图配置',
                    pageTitleTab: 'appletMapSet',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '地图配置',
                        active: false,
                    },],
                },

            },



            {
                //信息设置
                path: '/appletDetail/appletMessageSet',
                name: 'appletMessageSet',
                component: () => import( /* webpackChunkName: "appletMessageSet" */
                    '@/views/appletAdmin/exploitApplet/appletMessageSet/MessageSet.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '信息设置',
                    pageTitleTab: 'appletMessageSet',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '信息设置',
                        active: false,
                    },],
                },

            },



            {
                //订阅消息
                path: '/appletDetail/appletSubscribeInfo',
                name: 'appletSubscribeInfo',
                component: () => import( /* webpackChunkName: "appletSubscribeInfo" */
                    '@/views/appletAdmin/exploitApplet/appletSubscribeInfo/appletSubscribeInfo.vue'),
                meta: {
                    tabTitle: "开发",
                    tabTitleflage: true,
                    pageTitle: '订阅消息',
                    pageTitleTab: 'appletSubscribeInfo',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '订阅消息',
                        active: false,
                    },],
                },

            },
        ]
    }
]