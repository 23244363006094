<template>
	<div :style="windowsStyle">
		<router-view />
	</div>
</template>

<script>
	export default {
		computed: {
			windowsStyle() {
				if (this.$store.getters.get_windowsStyle) return this.$store.getters.get_windowsStyle
			},
		},

		beforeCreate() {},
		name: "App",
		setup() {

		}
	};
</script>

<style lang="scss">
	@import "@/@core/scss/style.scss";
	@import "@/@core/scss/util.scss";
	@import "@/assets/variables/variables.scss";
	@import "@/assets/font/font.css";


	::-webkit-scrollbar {
		display: none;
	}

	html {
		scrollbar-width: none;
	}

	body * {
		box-sizing: border-box;
	}

	body {
		// font-family: 'Regular';
		font-size: 16px;
		font-weight:400;
		// line-height: 1.45;
		margin: 0;
		cursor: default;
		overflow-x: scroll !important;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	#app {
		color: #222;
	}
</style>
