import axios from 'axios' //引入axios
import jwtDefaultConfig from './jwtConfig'

import router from './../../router/index'

import {
	ElMessage
} from 'element-plus'

let jwtConfig = {
	...jwtDefaultConfig
}

// axios.defaults.baseURL = '/api/'
// axios.defaults.baseURL = 'https://sa.home.135k.com/'
axios.defaults.baseURL = jwtConfig.saasBaseURL
// axios.defaults.baseURL = 'https://sa.client.135k.com/'


const instance = axios.create({
	timeout: 30000,
});

//请求和响应拦截可以根据实际项目需求进行编写
// 请求发起前拦截
instance.interceptors.request.use((config) => {
	//这里可以对接口请求头进行操作 如：config.headers['X-Token'] = token
	let token = localStorage.getItem(jwtConfig.storageTokenKeyName) || '';
	config.headers = {
		'Content-Type': 'application/json; charset=utf-8',
		'Authorization': `${jwtConfig.tokenType} ` + token,
	}
	// console.log("请求拦截", config);
	return config;
}, (error) => {
	// Do something with request error
	return Promise.reject(error)
})

// 响应拦截（请求返回后拦截）
instance.interceptors.response.use(response => {
	// console.log("响应拦截1", response);
	// console.log("响应拦截2", response.data);
	// console.log("响应拦截2", response.data.code);

	if (response.status == 200) {
		// console.log("响应成功", response);
		//登录token过期
		if (response.data.code == 500009) {
			localStorage.removeItem('userInfosass')
			localStorage.removeItem(jwtConfig.storageTokenKeyName)
			router.push({
				name: 'login'
			})
			ElMessage({
				message: '请重新登录',
				type: 'warning',
			})
		} else {
			// console.log("已登录", response);
			if (response.data) {
				console.log("成功", response.data);
				return response.data;
			} else {
				// console.log("请求失败", response);
				ElMessage({
					message: '网络错误，请刷新后再试',
					type: 'warning',
				})
			}
		}
	} else {
		ElMessage({
			message: response.data.message,
			type: 'warning',
		})
	}
}, error => {
	console.log('catch', error)
	return Promise.reject(error)
})

//按照请求类型对axios进行封装
const http = {
	get(url, data) {
		return instance.get(url, {
			params: data
		})
	},
	post(url, data) {
		return instance.post(url, data)
	},
	put(url, data) {
		return instance.put(url, data)
	},
	delete(url, data){
		return instance.delete(url, {
			params: data
		})
	}
}

export {
	http
}
