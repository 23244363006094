import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/assets/font/font.css';
import zhcn from 'element-plus/lib/locale/lang/zh-cn'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// document.onselectstart = function () { return false; }  //禁止页面复制


import jwtConfig from './@core/utils/jwtConfig.js'
app.config.globalProperties.$jwtConfig = jwtConfig;

// import http from './@core/utils/axios.js'
// app.config.globalProperties.$http = http;

import router from './router/index'
import store from './store/index.js'


app.use(store)
  .use(ElementPlus, {
    locale: zhcn,
  })
  .use(router)
  .mount('#app')
