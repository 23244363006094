export default [{
		//登录主页
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "login" */ '@/views/login/login.vue'),
		meta: {},
	},
	{
		//注册主页
		path: '/register',
		name: 'register',
		component: () => import( /* webpackChunkName: "register" */ '@/views/login/register.vue'),
		meta: {},
	},
	{
		//忘记密码
		path: '/password',
		name: 'password',
		component: () => import( /* webpackChunkName: "password" */ '@/views/login/password.vue'),
		meta: {},
	},

]
