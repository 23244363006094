export default [
    //插件
    {
        path: '/pluginsApplet',
        name: 'pluginsApplet',
        component: () => import('@/views/appletAdmin/pluginsApplet/index.vue'),
        children: [
            //代理商管理
            {
                path: '/appletDetail/appletAgent/CityAgencyIndex',
                name: 'CityAgencyIndex',
                component: () => import( /* webpackChunkName: "CityAgencyIndex" */
                    '@/views/appletAdmin/pluginsApplet/appletAgent/CityAgencyIndex.vue'),
                children: [{
                    //代理商管理  代理商申请
                    path: '/appletDetail/appletAgent/CityAgencyApply',
                    name: 'CityAgencyApply',
                    component: () => import( /* webpackChunkName: "CityAgencyApply" */
                        '@/views/appletAdmin/pluginsApplet/appletAgent/CityAgencyApply.vue'),
                    meta: {
                        tabTitle: "插件",
                        tabTitleflage: true,
                        pageTitle: '代理商管理',
                        pageTitleTab: 'CityAgencyApply',
                        pageTitleflage: true,
                        childrenTitle: '代理商申请',
                        childrenTitleTab: 'CityAgencyApply',
                        breadcrumb: [{
                            text: '代理商管理',
                            active: false,
                        },],
                    },
                },
                {
                    //代理商管理  代理商添加
                    path: '/appletDetail/appletAgent/CityAgencyAdd',
                    name: 'CityAgencyAdd',
                    component: () => import( /* webpackChunkName: "CityAgencyAdd" */
                        '@/views/appletAdmin/pluginsApplet/appletAgent/CityAgencyAdd.vue'),
                    meta: {
                        tabTitle: "插件",
                        tabTitleflage: true,
                        pageTitle: '代理商管理',
                        pageTitleTab: 'CityAgencyApply',
                        pageTitleflage: true,
                        childrenTitle: '代理商添加',
                        childrenTitleTab: 'CityAgencyAdd',
                        breadcrumb: [{
                            text: '代理商管理',
                            active: false,
                        },],
                    },
                },
                {
                    //代理商管理  代理商提现
                    path: '/appletDetail/appletAgent/CityAgencyWithdraw',
                    name: 'CityAgencyWithdraw',
                    component: () => import( /* webpackChunkName: "CityAgencyWithdraw" */
                        '@/views/appletAdmin/pluginsApplet/appletAgent/CityAgencyWithdraw.vue'),
                    meta: {
                        tabTitle: "插件",
                        tabTitleflage: true,
                        pageTitle: '代理商管理',
                        pageTitleTab: 'CityAgencyApply',
                        pageTitleflage: true,
                        childrenTitle: '代理商提现',
                        childrenTitleTab: 'CityAgencyWithdraw',
                        breadcrumb: [{
                            text: '代理商管理',
                            active: false,
                        },],
                    },
                },
                {
                    //代理商管理  提成设置
                    path: '/appletDetail/appletAgent/CityAgencyDeductSet',
                    name: 'CityAgencyDeductSet',
                    component: () => import( /* webpackChunkName: "CityAgencyDeductSet" */
                        '@/views/appletAdmin/pluginsApplet/appletAgent/CityAgencyDeductSet.vue'),
                    meta: {
                        tabTitle: "插件",
                        tabTitleflage: true,
                        pageTitle: '代理商管理',
                        pageTitleTab: 'CityAgencyApply',
                        pageTitleflage: true,
                        childrenTitle: '提成设置',
                        childrenTitleTab: 'CityAgencyDeductSet',
                        breadcrumb: [{
                            text: '代理商管理',
                            active: false,
                        },],
                    },
                },
                {
                    //代理商管理  代理商设置
                    path: '/appletDetail/appletAgent/CityAgencySet',
                    name: 'CityAgencySet',
                    component: () => import( /* webpackChunkName: "CityAgencySet" */
                        '@/views/appletAdmin/pluginsApplet/appletAgent/CityAgencySet.vue'),
                    meta: {
                        tabTitle: "插件",
                        tabTitleflage: true,
                        pageTitle: '代理商管理',
                        pageTitleTab: 'CityAgencyApply',
                        pageTitleflage: true,
                        childrenTitle: '代理商设置',
                        childrenTitleTab: 'CityAgencySet',
                        breadcrumb: [{
                            text: '代理商管理',
                            active: false,
                        },],
                    },
                },
                ]
            },

        ]
    }
]