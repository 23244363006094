export default [
    //工作台
    {
        path: '/homeDetail',
        name: 'homeDetail',
        component: () => import('@/views/saasAdmin/workbenchPage/index.vue'),
        children: [
            //概括
            {
                path: '/controlHome',
                name: 'controlHome',
                component: () => import( /* webpackChunkName: "controlHome" */
                    '@/views/saasAdmin/workbenchPage/controlHome/control.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '概括',
                    pageTitleTab: 'controlHome',
                    pageTitleflage: false,
                },
            },


            //概括  创建小程序  切换创建方式
            {
                path: '/homeDetail/appletSetting/cutAddMode',
                name: 'cutAddMode',
                component: () => import( /* webpackChunkName: "cutAddMode" */
                    '@/views/saasAdmin/workbenchPage/controlHome/appletSetting/cutAddMode.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '概括',
                    pageTitleTab: 'controlHome',
                    pageTitleflage: true,
                    goBack: true,
                    breadcrumb: [
                        {
                            text: '创建小程序',
                            active: false,
                        },
                        {
                            text: '切换创建方式',
                            active: false,
                        },],
                },
            },

            //概括  创建小程序  复用服务号资质创建小程序
            {
                path: '/homeDetail/appletSetting/reuseApplet',
                name: 'reuseApplet',
                component: () => import( /* webpackChunkName: "reuseApplet" */
                    '@/views/saasAdmin/workbenchPage/controlHome/appletSetting/reuseApplet.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '概括',
                    pageTitleTab: 'controlHome',
                    pageTitleflage: true,
                    breadcrumb: [
                        {
                            text: '创建小程序',
                            active: false,
                        },
                        {
                            text: '复用服务号资质创建小程序',
                            active: false,
                        },],
                },
            },


            //概括  创建小程序  已有小程序授权创建
            {
                path: '/homeDetail/appletSetting/alreadyHaveApplet',
                name: 'alreadyHaveApplet',
                component: () => import( /* webpackChunkName: "alreadyHaveApplet" */
                    '@/views/saasAdmin/workbenchPage/controlHome/appletSetting/alreadyHaveApplet.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '概括',
                    pageTitleTab: 'controlHome',
                    pageTitleflage: true,
                    breadcrumb: [
                        {
                            text: '创建小程序',
                            active: false,
                        },
                        {
                            text: '已有小程序授权创建',
                            active: false,
                        },],
                },
            },

            {
                //概括  创建小程序  法人扫脸认证小程序
                path: '/homeDetail/appletSetting/legalPersonFace',
                name: 'legalPersonFace',
                component: () => import( /* webpackChunkName: "legalPersonFace" */
                    '@/views/saasAdmin/workbenchPage/controlHome/appletSetting/legalPersonFace.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '概括',
                    pageTitleTab: 'controlHome',
                    pageTitleflage: true,
                    breadcrumb: [
                        {
                            text: '创建小程序',
                            active: false,
                        },
                        {
                            text: '法人扫脸认证小程序',
                            active: false,
                        },],
                },
            },

            {
                //小程序管理 
                path: '/homeDetail/appletManage',
                name: 'appletManage',
                component: () => import( /* webpackChunkName: "appletManage" */
                    '@/views/saasAdmin/workbenchPage/appletManage/appletlist.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '小程序管理',
                    pageTitleTab: 'appletManage',
                    pageTitleflage: true,

                    breadcrumb: [{
                        text: '小程序列表',
                        to: {
                            name: 'appletManage',
                        },
                        active: false,
                    },],
                },
            },

            // {
            // 	//小程序管理  小程序列表  添加小程序
            // 	path: '/homeDetail/appletManage/selectAddMode',
            // 	name: 'selectAddMode',
            // 	component: () => import( /* webpackChunkName: "selectAddMode" */
            // 		'@/views/saasAdmin/workbenchPage/appletManage/selectAddMode.vue'),
            // 	meta: {
            // 		tabTitle: "工作台",
            // 		tabTitleflage: true,
            // 		pageTitle: '小程序管理',
            // 		pageTitleTab: 'appletManage',
            // 		pageTitleflage: true,
            // 		breadcrumb: [{
            // 			text: '小程序列表',
            // 			to: {
            // 				name: 'appletManage',
            // 			},
            // 			active: true,
            // 		},
            // 		{
            // 			text: '添加小程序',
            // 			active: false,
            // 		},],
            // 	},
            // },

            {
                //小程序管理 列表 创建子账号
                path: '/homeDetail/appletManage/appletAddaccount',
                name: 'appletAddaccount',
                component: () => import( /* webpackChunkName: "appletAddaccount" */
                    '@/views/saasAdmin/workbenchPage/appletManage/appletDetail/appletAddaccount.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '小程序管理',
                    pageTitleTab: 'appletManage',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '小程序列表',
                        to: {
                            name: 'appletManage',
                        },
                        active: true,
                    }, {
                        text: '创建子账号',
                        active: false,
                    },],
                },
            },
            {
                //小程序管理 列表 子账号管理
                path: '/homeDetail/appletManage/appletAddadmin',
                name: 'appletAddadmin',
                component: () => import( /* webpackChunkName: "appletAddadmin" */
                    '@/views/saasAdmin/workbenchPage/appletManage/appletDetail/appletAddadmin.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '小程序管理',
                    pageTitleTab: 'appletManage',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '小程序列表',
                        to: {
                            name: 'appletManage',
                        },
                        active: true,
                    }, {
                        text: '子账号管理',
                        active: false,
                    },],
                },
            },

            {
                //小程序管理 新建小程序  小程序续费
                path: '/homeDetail/appletManage/appletRenew',
                name: 'appletRenew',
                component: () => import( /* webpackChunkName: "appletRenew" */
                    '@/views/saasAdmin/workbenchPage/appletManage/appletDetail/appletRenew.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '小程序管理',
                    pageTitleTab: 'appletManage',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '小程序列表',
                        to: {
                            name: 'appletManage',
                        },
                        active: true,
                    }, {
                        text: '续费',
                        active: false,
                    }],
                },
            },

            {
                //小程序管理 新建小程序  小程序续费 支付
                path: '/homeDetail/appletManage/appletRenewPay',
                name: 'appletRenewPay',
                component: () => import( /* webpackChunkName: "appletRenewPay" */
                    '@/views/saasAdmin/workbenchPage/appletManage/appletDetail/appletRenewPay.vue'),
                meta: {
                    tabTitle: "工作台",
                    tabTitleflage: true,
                    pageTitle: '小程序管理',
                    pageTitleTab: 'appletManage',
                    pageTitleflage: true,
                    breadcrumb: [{
                        text: '小程序列表',
                        to: {
                            name: 'appletManage',
                        },
                        active: true,
                    }, {
                        text: '续费',
                        to: {
                            name: 'appletRenew',
                        },
                        active: true,
                    }, {
                        text: '支付',
                        active: false,
                    }],
                },
            },
            

			{
				//应用商店 
				path: '/homeDetail/applicationStore',
				name: 'applicationStore',
				component: () => import( /* webpackChunkName: "applicationStore" */
					'@/views/saasAdmin/workbenchPage/applicationStore/applicationStore.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '应用商店',
					pageTitleTab: 'applicationStore',
					pageTitleflage: true,
					breadcrumb: [{
						text: '应用商店',
						active: false,
					}],
				},
			},


            
			{
				//权限管理 
				path: '/homeDetail/authorityManage',
				name: 'authorityManage',
				component: () => import( /* webpackChunkName: "authorityManage" */
					'@/views/saasAdmin/workbenchPage/authorityManage/authorityManage.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '权限管理',
					pageTitleTab: 'authorityManage',
					pageTitleflage: true,
					breadcrumb: [{
						text: '权限管理',
						active: false,
					}],
				},
			},

			{
				//权限管理  创建账户
				path: '/homeDetail/authorityManage/addAccount',
				name: 'addAccount',
				component: () => import( /* webpackChunkName: "addAccount" */
					'@/views/saasAdmin/workbenchPage/authorityManage/accountList/addAccount.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '权限管理',
					pageTitleTab: 'addAccount',
					pageTitleflage: true,
					breadcrumb: [{
						text: '权限管理',
						active: false,
					},
					{
						text: '账号列表',
						active: false,
					},
					{
						text: '创建账户',
						active: false,
					},],
				},
			},

			{
				//权限管理  编辑账户
				path: '/homeDetail/authorityManage/editAccount',
				name: 'editAccount',
				component: () => import( /* webpackChunkName: "editAccount" */
					'@/views/saasAdmin/workbenchPage/authorityManage/accountList/editAccount.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '权限管理',
					pageTitleTab: 'editAccount',
					pageTitleflage: true,
					breadcrumb: [{
						text: '权限管理',
						active: false,
					},
					{
						text: '账号列表',
						active: false,
					},
					{
						text: '编辑账户',
						active: false,
					},],
				},
			},


			{
				//权限管理  创建角色
				path: '/homeDetail/authorityManage/addRole',
				name: 'addRole',
				component: () => import( /* webpackChunkName: "addRole" */
					'@/views/saasAdmin/workbenchPage/authorityManage/roleManage/addRole.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '权限管理',
					pageTitleTab: 'addRole',
					pageTitleflage: true,
					breadcrumb: [{
						text: '权限管理',
						active: false,
					},
					{
						text: '角色管理',
						active: false,
					},
					{
						text: '创建角色',
						active: false,
					},],
				},
			},
			{
				//权限管理  编辑角色
				path: '/homeDetail/authorityManage/editRole',
				name: 'editRole',
				component: () => import( /* webpackChunkName: "editRole" */
					'@/views/saasAdmin/workbenchPage/authorityManage/roleManage/editRole.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '权限管理',
					pageTitleTab: 'editRole',
					pageTitleflage: true,
					breadcrumb: [{
						text: '权限管理',
						active: false,
					},
					{
						text: '角色管理',
						active: false,
					},
					{
						text: '编辑角色',
						active: false,
					},],
				},
			},


            

			{
				//财务管理 
				path: '/homeDetail/financialManage',
				name: 'financialManage',
				component: () => import( /* webpackChunkName: "financialManage" */
					'@/views/saasAdmin/workbenchPage/financialManage/financialManage.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '财务管理',
					pageTitleTab: 'financialManage',
					pageTitleflage: true,
					breadcrumb: [{
						text: '财务管理',
						active: false,
					}],
				},
			},

            

			{
				//帮助中心 
				path: '/homeDetail/helpCenter',
				name: 'helpCenter',
				component: () => import( /* webpackChunkName: "helpCenter" */
					'@/views/saasAdmin/workbenchPage/helpCenter/helpCenter.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '帮助中心',
					pageTitleTab: 'helpCenter',
					pageTitleflage: true,
					breadcrumb: [{
						text: '帮助中心',
						active: false,
					}],
				},
			},


			{
				//消息中心 
				path: '/homeDetail/messageCenter',
				name: 'messageCenter',
				component: () => import( /* webpackChunkName: "messageCenter" */
					'@/views/saasAdmin/workbenchPage/messageCenter/messageCenter.vue'),
				meta: {
					tabTitle: "工作台",
					tabTitleflage: true,
					pageTitle: '消息中心',
					pageTitleTab: 'messageCenter',
					pageTitleflage: true,
					breadcrumb: [{
						text: '消息中心',
						active: false,
					}],
				},
			},
            



        ]
    }
]