
import controlApplet from './controlApplet/index.js'//概括
import exploitApplet from './exploitApplet/index.js'//开发
import pluginsApplet from './pluginsApplet/index.js'//插件

export default [
    //小程序管理
    {
        path: '/appletDetail',
        name: 'appletDetail',
        component: () => import('@/views/appletAdmin/index.vue'),
        children: [
            ...controlApplet,
            ...exploitApplet,
            ...pluginsApplet,
        ]
    }
]