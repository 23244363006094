export default [
    //概况
    {
        path: '/controlApplet',
        name: 'controlApplet',
        component: () => import('@/views/appletAdmin/controlApplet/index.vue'),
        children: [
            //概况
            {
                path: '/appletControl',
                name: 'appletControl',
                component: () => import( /* webpackChunkName: "appletControl" */
                    '@/views/appletAdmin/controlApplet/appletControl/appletOverview.vue'),
                meta: {
                    tabTitle: "概况",
                    tabTitleflage: true,
                    pageTitle: '概况',
                    pageTitleTab: 'appletControl',
                    pageTitleflage: false,
                },
            },

        ]
    }
]