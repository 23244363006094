import {
	createRouter,
	createWebHashHistory
} from "vue-router";

import jwtDefaultConfig from '@/@core/utils/jwtConfig.js'
// console.log(jwtDefaultConfig)


// import homepage from './routers/homepage.js'
// import applicenter from './routers/applicenter.js'
// import appletdetail from './routers/appletdetails.js'
// import financecenter from './routers/financecenter.js'
// import accountmanage from './routers/accountmanage.js'
import login from './routers/login.js'

import saasAdmin from './saasAdmin/index.js'//saas后台管理
import appletAdmin from './appletAdmin/index.js'//小程序后台管理




export const routes = [{
		path: '/',
		redirect: {
			name: 'controlHome'
		}
	},
	...saasAdmin,
	...appletAdmin,
	// ...applicenter,
	// ...appletdetail,
	// ...financecenter,
	// ...accountmanage,
	...login,
]


const router = createRouter({
	history: createWebHashHistory(),
	routes: routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	},
})

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
	// 判断有没有登录
	if (!localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)) {
		if (to.name == "login") {
			next();
		} else {
			router.push('login')
		}
	} else {
		next();
	}
})


router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})


export default router
